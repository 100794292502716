<template>
  <div class="flex-center position-ref">
    <Loading :loading="loading"></Loading>
    <div class="content">
      <img src="/assets/logos/k12net.png">
      <img v-if="this.platform !== null" src="/assets/logos/arrow.jpg">
      <img v-if="this.platform === 'kidzwonder'" src="/assets/logos/kidzwonder.png">
      <img v-else-if="this.platform === 'uesportals'" src="/assets/logos/ues-portals.png">
      <img v-else-if="this.platform === 'myeduclass'" src="/assets/logos/myeduclass.png">
      <img v-else-if="this.platform === 'highlights-library'" src="/assets/logos/highlights-library.png">
      <img v-else-if="this.platform === 'ell'" src="/assets/logos/ell.png">
      <img v-else-if="this.platform === 'news-o-matic'" src="/assets/logos/news-o-matic.png">
      <img v-else-if="this.platform === 'raz-plus'" src="/assets/logos/raz-plus.png">
      <img v-else-if="this.platform === 'sensation-english'" src="/assets/logos/sensation-english.png">
      <br /><br /><br />
      <h3>Lütfen bekleyiniz, ilgili platforma yönlendiriliyorsunuz.</h3>
    </div>
  </div>
</template>

<script>
import Loading from "@/components/Loading";
import axios from "axios";
import CryptoJS from "crypto-js";

export default {
  name: "K12Net",
  components: {
    Loading
  },
  data() {
    return {
      loading: false,
      // ueslms_service_baseurl: 'http://localhost:4000', // local
      ueslms_service_baseurl: 'https://ueslms-service-api.ey.r.appspot.com', // prod
      k12net_code: null,
      platform: null,
      first_token: null,
      user_id: null,
      user_role: null,
      second_token: null,
      student_data: {},
      teacher_data: {},
      k12UserData: {},
      uesLmsUserData: {},
    }
  },
  beforeMount() {
    this.loading = true;
    const query = this.$route.query;
    if (query.code) {
      this.k12net_code = query.code;
      this.platform = query.state ? query.state : 'n/a';
      this.k12FNetFirstToken();
    }
  },
  methods: {
    platformRedirUrl() {
      const url = window.location.href;

      if (url.startsWith('https://localhost')) {
        return 'https://localhost:8080/#/k12net';
      }

      return 'https://service.ueslms.com/#/k12net';
    },

    async k12FNetFirstToken() {
      let url = `${this.ueslms_service_baseurl}/sso/k12net/firstToken`;
      let data = {
        code: this.k12net_code,
        redirect_uri: this.platformRedirUrl(),
      }
      await axios.post(url, data)
          .then(response => {
            this.first_token = response.data.access_token;
            // console.log('first token', this.first_token)
            this.k12NetUserInfo()
          })
          .catch(response => {
            console.log('ERR', response);
            this.loading = false;
          })
    },

    async k12NetUserInfo() {
      let url = `${this.ueslms_service_baseurl}/sso/k12net/userInfo`;
      let data = {
        bearerToken: this.first_token
      }
      await axios.post(url, data)
          .then(response => {
            this.user_id = response.data.user_id.replace(/[{}]/g, '');
            this.user_role = response.data.user_role;
            // console.log('userInfo', response.data)
            // gelen öğrenci/öğretmen id'si ueslms tarafında tanımlı mı kontrol ediliyor.
            // eğer öğrenci/öğretmen varsa istediği platforma yönlendiriliyor.
            // yoksa yeni öğrenci/öğretmen ueslms'te oluşturuluyor.
            this.ueslmsCheckUser()
          })
          .catch(response => {
            console.log('ERR', response);
            this.loading = false;
          })
    },

    async k12FNetSecondToken() {
      let url = `${this.ueslms_service_baseurl}/sso/k12net/secondToken`;
      await axios.post(url)
          .then(response => {
            this.second_token = response.data.access_token
            // console.log('second_token', this.second_token)
            if (this.user_role === 'Student') {
              this.k12NetFetchStudent()
            } else {
              this.k12NetFetchTeacher()
            }
          })
          .catch(response => {
            console.log('ERR', response);
            this.loading = false;
          })
    },

    async k12NetFetchStudent() {
      let url = `${this.ueslms_service_baseurl}/sso/k12net/fetchStudent`;
      let data = {
        bearerToken: this.second_token,
        user_id: this.user_id
      }
      await axios.post(url, data)
          .then(response => {
            this.loading = false
            console.log('fetchStudent', response.data.student)
            this.student_data = response.data.student
            // kullanıcı datası düzgün hale getiriliyor

            this.k12UserData = {
              user_role: 'student',
              platform: this.platform,
              k12net_student: response.data.student,

              // k12_user_id: response.data.student.id,
              // user_fullname: response.data.student.fullName,
              // k12_enrollment: response.data.student.enrollment,
              // k12_school_name: response.data.student.enrollment.school.lea.parent.name,
              // k12_school_id: response.data.student.enrollment.school.lea.parent.id,
              // k12_campus_name: response.data.student.enrollment.school.name,
              // k12_campus_id: response.data.student.enrollment.school.id,
              // k12_class_name: response.data.student.enrollment.homeroom ?? '1-A',
              // k12_class_id: response.data.student.enrollment.id,
            }

            this.ueslmsCreateUser()
          })
          .catch(response => {
            console.log('ERR', response);
            this.loading = false;
          })
    },

    k12NetFetchTeacher() {
      //
    },

    goK12Net() {
      this.loading = false;
      let secretKey = null;
      let redirectUrl = null;
      if (this.platform === 'kidzwonder') {
        secretKey = 'HJx7t2KxTCHYvtzB4w7AQhVRaf7bTm9WWFQpBMiR@j7Gpgw8';
        redirectUrl = 'https://www.kidzwonder.com/#/login?sso=';
      }
      else if (this.platform === 'highlights-library') {
        secretKey = 'HJx7t2KxTCHYvtzB4w7AQhVRaf7bTm9WWFQpBMiR@j7Gpgw8';
        redirectUrl = 'https://www.hlllogin.com/#/sso/?q='
      }
      else if (this.platform === 'uesportals') {
        secretKey = 'HJx7t2KxTCHYvtzB4w7AQhVRaf7bTm9WWFQpBMiR@j7Gpgw8';
        redirectUrl = 'https://www.uesportals.com/#/login/signin?sso='
      }
      else if (this.platform === 'myeduclass') {
        secretKey = 'HJx7t2KxTCHYvtzB4w7AQhVRaf7bTm9WWFQpBMiR@j7Gpgw8';
        redirectUrl = 'https://lms.myeduclass.com/#/?sso='
      }
      else if (this.platform === 'ell') {
        secretKey = 'Pbt6DND9k5tu7Tdj284-1szLNPEXwcVznFMN4G@UdQtZ7.GEU7!BPy';
        redirectUrl = 'https://service.ueslms.com/#/efs/?sso='
      }
      else if (this.platform === 'raz-plus') {
        secretKey = 'DZYQSbQQKmruR4ZYOLD%s7163BZjoyViqF7gWy.A6Gd9JAyXCL!eNGsrEJVcPUe';
        redirectUrl = 'https://service.ueslms.com/#/raz-plus/?sso='
      }
      else if (this.platform === 'sensations-english') {
        secretKey = 'Pbt6DND9k5tu7Tdj284-1szLNPEXwcVznFMN4G@UdQtZ7.GEU7!BPy';
        redirectUrl = 'https://service.ueslms.com/#/sensations/?sso='
      }
      else if (this.platform === 'news-o-matic') {
        secretKey = 'Pbt6DND9k5tu7Tdj284-1szLNPEXwcVznFMN4G@UdQtZ7.GEU7!BPy';
        redirectUrl = 'https://service.ueslms.com/#/nom/?sso='
      }

      location.href = redirectUrl + this.platformHandle(secretKey);
    },

    platformHandle(secretKey) {
      const textJson = JSON.stringify(this.uesLmsUserData);
      const uriText = decodeURIComponent(textJson);
      const ciphertext = CryptoJS.AES.encrypt(uriText, secretKey).toString();
      return btoa(ciphertext);
    },

    async ueslmsCheckUser() {
      await axios.post('https://www.ueslms.com/api/v2/k12net/user/check', {k12_user_id: this.user_id})
          .then(response => {
            if (response.data.success) {
              this.uesLmsUserData = response.data.user;
              this.goK12Net()
            } else {
              this.k12FNetSecondToken();
            }
          })
          .catch(response => {
            console.log('ERR', response)
          })
    },

    async ueslmsCreateUser() {
      await axios.post('https://www.ueslms.com/api/v2/k12net/user/create', {user: this.k12UserData})
          .then(response => {
            // console.log(response.data)
            this.uesLmsUserData = response.data.user;
            this.goK12Net()
          })
          .catch(response => {
            console.log('ERR', response)
          })
    },
  }
}
</script>

<style scoped>
html, body {
  /* background-color: #37474f; */
  background-color: #712481;
  height: 100vh;
  margin: 0;
}

.flex-center {
  align-items: center;
  display: flex;
  justify-content: center;
}

.position-ref {
  position: relative;
}

.content {
  margin-top: 50px;
  text-align: center;
}
</style>
