<template>
  <div>
    <Loading :loading="loading"></Loading>
    <div v-if="userInfo" style="margin: 50px auto 0 auto; padding: 10px; background-color: #efefef; color: #000; line-height: 26px; width: 600px;">
      <h1 style="text-align: center; color: navy;">KeyConnect</h1>
      Platforma giriş yapabilmek için kullanıcı bilgileriniz aşağıdaki gibidir:<br />
      <br />
      Kullanıcı Adı: <strong>{{ userData.username }}</strong><br />
      Parola: <strong>123456</strong><br />
      <br />
      <a href="https://app.discoveryeducation.com/learn/signin?UR=S" target="_blank">Platforma Git &raquo;</a>
    </div>
  </div>
</template>

<script>
// import axios from "axios";

import Loading from "@/components/Loading.vue";
import CryptoJS from "crypto-js";
import axios from "axios";

export default {
  name: "KeyConnect",
  components: {
    Loading
  },
  data() {
    return {
      loading: false,
      secretKey: 'EDcseSrkMx%Nvug6VptLyR85Pq.KZ4J-Yd7QbA9H2F!XBGCa3jWzTf',
      userData: null,
      userInfo: false,
    }
  },
  beforeMount() {
    const query = this.$route.query;
    if (query.sso) {
      // gelen data çözülüyor
      const decode = decodeURIComponent(atob(query.sso));
      const bytes = CryptoJS.AES.decrypt(decode, this.secretKey).toString(CryptoJS.enc.Utf8);
      this.userData = JSON.parse(bytes);
      this.userData.branch = this.userData.class[0]['branch'];
      this.userData.class = this.userData.class[0]['grade'];
      this.ueslmsCheckUser()
    }
  },
  methods: {
    ueslmsCheckUser() {
      axios.post('https://www.ueslms.com/api/v2/hlllogin/user/check2', {user: this.userData})
          .then(response => {
            if (response.data.success) {
              this.userData.user_id = response.data.user.user_id;
              this.userData.campus_id = response.data.user.campus_id;
              this.keyConnectInfo()
            } else {
              this.ueslmsCreate()
            }
          })
          .catch(response => {
            console.log('ERR', response)
          })
    },

    ueslmsCreate() {
      axios.post('https://www.ueslms.com/api/v2/hlllogin/user/create', this.userData)
          .then(response => {
            this.userData.user_id = response.data.user.user_id;
            this.userData.campus_id = response.data.user.campus_id;
            this.keyConnectInfo()
          })
          .catch(response => {
            console.log('ERR', response)
          })
    },

    keyConnectInfo() {
      this.userInfo = true
    }
  }
}
</script>
