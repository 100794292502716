<template>
  <div>
    <Loading :loading="loading"></Loading>
    Maneno SSO
  </div>
</template>

<script>
import Loading from "@/components/Loading";
import axios from "axios";
import CryptoJS from "crypto-js";

export default {
  name: "Maneno",
  components: {
    Loading
  },
  data() {
    return {
      loading: false,
      secretKey: 'Pbt6DND9k5tu7Tdj284-1szLNPEXwcVznFMN4G@UdQtZ7.GEU7!BPy',
      userDataManeno: null,
      userData: null,
    }
  },
  beforeMount() {
    this.loading = true
    const query = this.$route.query;
    if (query.sso) {
      // gelen data çözülüyor
      const decode = decodeURIComponent(atob(query.sso));
      const bytes = CryptoJS.AES.decrypt(decode, this.secretKey).toString(CryptoJS.enc.Utf8);
      this.userData = JSON.parse(bytes);
      // console.log(this.userData)
      this.ueslmsCheckUser();
    }
  },
  methods: {
    async goManeno() {
      await axios.post('https://manenouk.azurewebsites.net/api/account/v2/uessso',
          {
            username: this.userData.username,
            firstname: this.userData.first_name,
            lastname: this.userData.last_name,
            role: this.userData.role_type,
            schoolid: this.userData.school_id,
            campusid: this.userData.campus_id,
            campusname: this.userData.campus_name,
            schoolname: this.userData.school_name,
            classname: this.userData.class[0]['grade'] + "-" + this.userData.class[0]['branch'],
            classes: this.userData.class.map(x => `${ x.grade + "-" + x.branch }`),
            readinglevel: 1,
            gradelevel: this.userData.class[0]['grade'],
          },
          {
            headers: {
              "Authorization": "37e3718e-f43d-40ad-8e1a-98648a6bacec",
            }
      })
          .then(response => {
            if (response.status != 200) {
              alert('bir hata olustu')
            }
            // console.log(response)
            window.location.href = response.data
          })
          .catch(response => {
            console.log('ERR', response)
            this.loading = false
          })
    },

    ueslmsCheckUser() {
      axios.post('https://www.ueslms.com/api/v2/hlllogin/user/check2', {user: this.userData})
          .then(response => {
            if (response.data.success) {
              this.goManeno()
            } else {
              this.ueslmsCreate()
            }
          })
          .catch(response => {
            console.log('ERR', response)
          })
    },

    ueslmsCreate() {
      axios.post('https://www.ueslms.com/api/v2/hlllogin/user/create', this.userData)
          .then(response => {
            // user_id ekleniyor
            this.userData.user_idx = response.data.user.user_id;
            // campus_id, ueslms id'si olarak degistiriliyor
            this.userData.campus_idx = response.data.user.campus_id;
            this.goManeno()
          })
          .catch(response => {
            console.log('ERR', response)
          })
    },
  }
}
</script>
