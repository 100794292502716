import Home from './components/Home';
import Okyanus from './components/Okyanus';
import NOM from './components/NOM';
import RLP from './components/RLP';
import UESRLPLogin from './components/UESRLPLogin';
import EFS from './components/EFS';
import Maneno from './components/Maneno';
import Sensations from "@/components/Sensations.vue";
import K12Net from "@/components/K12Net.vue";
import K12NetRedirect from "@/components/K12NetRedirect.vue";
import RazPlus from "@/components/RazPlus.vue";
import KeyConnect from "@/components/KeyConnect.vue";
// import Doga from './components/Doga';

export const routes = [
  { path: '/', component: Home, },
  { path: '/okyanus', component: Okyanus, },
  { path: '/nom', component: NOM, },
  { path: '/rlp', component: RLP, },
  { path: '/ues-rlp-login', component: UESRLPLogin, },
  { path: '/efs', component: EFS, },
  { path: '/maneno', component: Maneno, },
  { path: '/sensations', component: Sensations, },
  { path: '/k12net', component: K12Net, },
  { path: '/raz-plus', component: RazPlus, },
  { path: '/k12net-redirect', component: K12NetRedirect, },
  { path: '/key-connect', component: KeyConnect, },
  // { path: '/doga', component: Doga, }
];
