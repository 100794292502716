<template>
  <div>
    <Loading :loading="loading"></Loading>
    <h2>Okyanus Web Service</h2>
  </div>
</template>

<script>
/*
4. sınıf öğrenci: 0x02000000991B4A22C1236D782F036757BF6A7EFC19E0B28A2F7711B7995A201DEF95636B
5. sınıf öğrenci: 0x020000008F585971A8C45C7DE2F69AEAEE1E28C5D6ADD1C34D0409CD590483232D89665D
9. sınıf öğrenci: 0x02000000507096385BC45863833CF9A3B37502D1DA2B583A6442BA4D080D5928FCBFDC58
 */
import Loading from "@/components/Loading";
import axios from "axios";

export default {
  name: "Okyanus",
  components: {
    Loading
  },
  data() {
    return {
      loading: false,
      apiKey: 'BC63F515-92E5-42BC-BBD7-AD00C64E8D3E',
      userKey: null,
      userRole: null,
      userId: 0,
      secretKey: "HJx7t2KxTCHYvtzB4w7AQhVRaf7bTm9WWFQpBMiR@j7Gpgw8",
      userData: {},
    }
  },
  beforeMount() {
    this.getUserKey();
    // this.getUserDetailsForOkyanus();
    this.getUserDetailsForUesLms();
  },
  methods: {
    getUserKey() {
      if (this.$route.query.ogrenciId) {
        this.userKey = this.$route.query.ogrenciId
        this.userRole = 'student'
      } else if (this.$route.query.ogretmenId) {
        this.userKey = this.$route.query.ogretmenId
        this.userRole = 'teacher'
      }
    },
    // URL PARAMETRESINDEN GELEN KEY DEGERI OKYANUS SERVISINE GONDERILIYOR
    // KULLANICI ID OGRENILIYOR
    // async getUserDetailsForOkyanus() {
    //   this.loading = true
    //   await axios.post('https://pusulam.okyanuskoleji.k12.tr/Pusulamapi/api/DisKaynak/SifreCoz', {
    //     KEY: this.userKey,
    //     APIKEY: this.apiKey
    //   })
    //       .then(response => {
    //         if (response.status === 200) {
    //           this.userId = response.data.id
    //           this.getUserDetailsForUesLms()
    //         } else {
    //           console.log('ERR: Okyanus servisinden bilgi alinamadi.')
    //         }
    //         this.loading = false
    //       })
    //       .catch(response => {
    //         console.log('ERR', response)
    //         this.loading = false
    //       })
    // },
    // OKYANUSTAN OGRENILEN ID UESLMS SERVISINE GONDERILEREK KULLANICI BILGILERI OGRENILIYOR
    async getUserDetailsForUesLms() {
      this.loading = true
      await axios.post('https://www.ueslms.com/api/v2/okyanus/user/check', { key: this.userKey, apikey: this.apiKey })
          .then(response => {
            if (response.data.success) {
              this.userData = response.data.user
              // if (response.data.user.class === 4) {
              //  this.goHLL()
              // }
              // else
              if (response.data.user.class === 5) {
                this.goHexagonia()
              }
              else if (response.data.user.class === 9) {
                this.goMEC()
              }
            } else {
              console.log('ERR: UESLMS servisinden bilgi alinamadi.')
            }
            this.loading = false
          })
          .catch(response => {
            console.log('ERR', response)
            this.loading = false
          })
    },
    goHLL() {
      if (this.userData === undefined || !this.userData.company_code) {
        this.error = true
        this.errorMessage = 'Company Code LMS sisteminde tanımlı değil.'
      }

      if (!this.error) {
        // console.log('GO HLL', this.userData)
        window.go_hll(this.userData)
      }
    },
    goHexagonia() {
      if (this.userRole === 'teacher') {
        window.go_uesportals(this.userData)
      } else {
        // console.log('GO HEXA', this.userData)
        window.go_hexagonia(this.userData)
      }
    },
    goMEC() {
      // console.log('GO MEC', this.userData)
      window.go_myeduclass(this.userData)
    },
  }
}
</script>
