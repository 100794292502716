<template>
  <div>
    <Loading :loading="loading"></Loading>
    RLP Web Service
  </div>
</template>

<script>
import Loading from "@/components/Loading";
import CryptoJS from 'crypto-js';
import axios from "axios";

export default {
  name: "RLP",
  components: {
    Loading
  },
  data() {
    return {
      loading: false,
      secretKey: 'VswFYGzh2k45N7Q8R9@TBuCVexFY.G2J3K4N6p7Q%9SATB-VdWeXFZHJ3m5N',
      secretKey2: 'yPPLPWiyjAP7KwWRJMakBTWFTcAXwefUBCqRXw9tyaENYxiPTmiQDtTVrFap9Lz4', // RLP servisine giderken
      userData: null,
    }
  },
  beforeMount() {
    const query = this.$route.query;
    if (query.sso) {
      // gelen data çözülüyor
      const decode = decodeURIComponent(atob(query.sso));
      const bytes = CryptoJS.AES.decrypt(decode, this.secretKey).toString(CryptoJS.enc.Utf8);
      this.userData = JSON.parse(bytes);

      // yeni parametreler ekleniyor
      this.userData.country = "Turkey";
      this.userData.start_date = "2023-08-30 00:00:00";
      this.userData.end_date = "2024-08-30 23:59:59";

      // Doga okullari icin eklemeler
      // if (this.userData.school_id === 11558) {
        this.userData.branch = this.userData.class[0]['branch'];
        this.userData.class = this.userData.class[0]['grade'];
      // }

      this.ueslmsCheckUser()
    }
  },
  methods: {
    goRLPSso() {
      console.log('userData', this.userData)
      // Dis servise gonderilmek uzere data hazirlaniyor
      var textJson = JSON.stringify(this.userData);
      var uriText = decodeURIComponent(textJson)
      var ciphertext = CryptoJS.AES.encrypt(uriText, this.secretKey2).toString();
      console.log('ciphertext', ciphertext);
      window.location.href = "https://richmondlp.com/ueshub/sessions/new?token=" + btoa(ciphertext);
    },
    ueslmsCheckUser() {
      axios.post('https://www.ueslms.com/api/v2/hlllogin/user/check2', {user: this.userData})
          .then(response => {
            if (response.data.success) {
              // console.log('check user response.data.user', response.data.user)
              // user_id ekleniyor
              this.userData.user_id = response.data.user.user_id;
              // campus_id, ueslms id'si olarak degistiriliyor
              this.userData.campus_id = response.data.user.campus_id;
              this.goRLPSso()
            } else {
              this.ueslmsCreate()
            }
          })
          .catch(response => {
            console.log('ERR', response)
          })
    },

    ueslmsCreate() {
      axios.post('https://www.ueslms.com/api/v2/hlllogin/user/create', this.userData)
          .then(response => {
            // console.log('ueslms create response.data.user', response.data.user)
            // user_id ekleniyor
            this.userData.user_id = response.data.user.user_id;
            // campus_id, ueslms id'si olarak degistiriliyor
            this.userData.campus_id = response.data.user.campus_id;
            this.goRLPSso()
          })
          .catch(response => {
            console.log('ERR', response)
          })
    },
  }
}
</script>
