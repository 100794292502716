<template>
  <div class="flex-center position-ref full-height">
    <div class="content">
      <Loading :loading="loading"></Loading>
      <img src="/assets/logos/sensation-english.png">
      <h3 v-if="this.$route.query.sso">Lütfen bekleyiniz, ilgili platforma yönlendiriliyorsunuz.</h3>
    </div>
  </div>
</template>

<script>
import Loading from "@/components/Loading";
import axios from "axios";
import CryptoJS from "crypto-js";
export default {
  name: "Sensations",
  components: {
    Loading
  },
  data() {
    return {
      loading: false,
      secretKey: 'Pbt6DND9k5tu7Tdj284-1szLNPEXwcVznFMN4G@UdQtZ7.GEU7!BPy',
      userData: null,
      userLmsData: null,
      seBaseUrl: 'https://pro.sensationsenglish.com/api/v1',
      sessionId: null,
      classroomId: null,
      classroomIds: [],
      user_type: 0, // 2 ogrenci, 3 ogretmen
    }
  },
  beforeMount() {
    const query = this.$route.query;
    if (query.sso) {
      this.loading = true
      // gelen data çözülüyor
      const decode = decodeURIComponent(atob(query.sso));
      const bytes = CryptoJS.AES.decrypt(decode, this.secretKey).toString(CryptoJS.enc.Utf8);
      this.userData = JSON.parse(bytes);
      // console.log('userData', this.userData)
      this.ueslmsCheckUser();
    }
  },
  methods: {
    async goSensationEnglish() {
      // console.log('userLmsData', this.userLmsData)
      // 1. Admin ile token al
      await this.getAdminToken()
      if (this.sessionId) {

        // 2. Sınıf olustur ya da varolanı kullan

        if (Object.keys(this.userLmsData.se_classroom_ids).length) {
          this.classroomIds = this.userLmsData.se_classroom_ids
          // console.log(this.classroomIds)
        } else {
          // console.log('createClass calissin')
          await this.createClass()
        }


        if (this.userLmsData.se_status === 0) {

          if (this.userLmsData.type === 'student') {
            // 3. Ogrenci olustur
            await this.createStudent()
            // 4. Ogrenciyi Sinifa ata
            await this.assignStudentToClass()

          } else {

            // 3. Ogretmen olustur
            await this.createTeacher()
            // 4. Ogretmeni Sinifa ata
            await this.assignTeacherToClass()

          }

          // 5. ueslms'te kullanicinin 'se_status' durumunu guncelle
          await this.ueslmsUserUpdate()
        }

        // 5. kullanici ile login ol
        await this.userAuth()

      }
    },

    async getAdminToken() {
      await axios.post(this.seBaseUrl + '/admin/auth',
          {
            email: this.userLmsData.se_email,
            password: btoa(this.userLmsData.se_password)
          },
          {
            headers: {
              sensationkey: this.userLmsData.sensationkey,
            }
          })
          .then(response => {
            this.sessionId = response.data.sessionId
          })
          .catch(response => {
            console.log('ERR', response)
            this.loading = false
          })
    },

    createClass() {
      let siniflar = []
      this.userData.class.forEach((value) => {
        // console.log(value)
        var sinif_adi =`${value.grade}-${value.branch}`

        axios.post(this.seBaseUrl + '/classroom',
            {
              classroom_name: sinif_adi
            },
            {
              headers: {
                sensationkey: this.userLmsData.sensationkey,
                sessionId: this.sessionId
              }
            })
            .then(response => {
              if (response.data.status) {
                siniflar[sinif_adi] = response.data.classroomId
                // yeni olusturulan sinifa classroom_id eklemesi yapiliyor
                this.ueslmsClassUpdate(sinif_adi, response.data.classroomId)
              }
            })
            .catch(response => {
              console.log('ERR', response)
              this.loading = false
            })

      })

      // console.log('siniflar', siniflar)
    },

    async createStudent() {
      await axios.post(this.seBaseUrl + '/student/users',
          {
            students: [{
              name: `${this.userData.first_name} ${this.userData.last_name}`,
              email: this.userData.username,
              defaultLevel: 1,
              password: "123456"
            }]
          },
          {
            headers: {
              sensationkey: this.userLmsData.sensationkey,
              sessionId: this.sessionId
            }
          })
          // .then(response => {
            // console.log('student created')
            // console.log(response.data)
          // })
          .catch(response => {
            console.log('ERR', response)
            this.loading = false
          })
    },

    async createTeacher() {
      await axios.post(this.seBaseUrl + '/teacher/users',
          {
            teachers: [{
              name: `${this.userData.first_name} ${this.userData.last_name}`,
              email: this.userData.username,
              password: "Ues1234!"
            }]
          },
          {
            headers: {
              sensationkey: this.userLmsData.sensationkey,
              sessionId: this.sessionId
            }
          })
          // .then(response => {
            // console.log('teacher created')
            // console.log(response.data)
          // })
          .catch(response => {
            console.log('ERR', response)
            this.loading = false
          })
    },

    assignStudentToClass() {
      Object.values(this.userLmsData.se_classroom_ids).forEach((value) => {
        // console.log(value)
        // console.log(this.userData.username)
      axios.post(this.seBaseUrl + '/classroom/students',
          {
            classroom_id: value,
            students: [
              this.userData.username,
            ]
          },
          {
            headers: {
              sensationkey: this.userLmsData.sensationkey,
              sessionId: this.sessionId
            }
          })
          // .then(response => {
            // console.log('assign student to class')
            // console.log(response.data)
          // })
          .catch(response => {
            console.log('ERR', response)
            this.loading = false
          })
      })
    },

    assignTeacherToClass() {
      Object.values(this.userLmsData.se_classroom_ids).forEach((value) => {
        // console.log(value)
        // console.log(this.userData.username)
        axios.post(this.seBaseUrl + '/classroom/teachers',
            {
              classroom_id: value,
              teachers: [
                this.userData.username
              ]
            },
            {
              headers: {
                sensationkey: this.userLmsData.sensationkey,
                sessionId: this.sessionId
              }
            })
            // .then(response => {
              // console.log('assign teacher to class')
              // console.log(response.data)
            // })
            .catch(response => {
              console.log('ERR', response)
              this.loading = false
            })
      })
    },

    async ueslmsUserUpdate() {
      await axios.post('https://www.ueslms.com/api/v2/hlllogin/user/update', { email: this.userData.username })
    },

    async ueslmsClassUpdate(class_branch, classroom_id) {
      await axios.post('https://www.ueslms.com/api/v2/hlllogin/class/update', {
        user: this.userData,
        class_branch: class_branch,
        classroom_id: classroom_id })
          // .then(response => {
          //   console.log('class_update', response.data)
          // });
    },

    async userAuth() {
      await axios.post('https://resources.sensationsenglish.com/api/v1/user/auth',
          {
            email: this.userData.username,
            login_type: (this.userLmsData.type === 'student') ? 2 : 3, // 2 ogrenci, 3 ogretmen
          },
          {
            headers: {
              sensationkey: this.userLmsData.sensationkey,
              sessionId: this.sessionId
            }
          })
          .then(response => {
            if (response.data.status) {
              window.location.href = response.data.redirectUrl
            }
          })
          .catch(response => {
            console.log('ERR', response)
            this.loading = false
          })
    },

    ueslmsCheckUser() {
      axios.post('https://www.ueslms.com/api/v2/hlllogin/user/check2', { user: this.userData })
          .then(response => {
            if (response.data.success) {
              this.userLmsData = response.data.user
              this.goSensationEnglish()
            } else {
              this.ueslmsCreate()
            }
          })
          .catch(response => {
            console.log('ERR', response)
          })
    },

    ueslmsCreate() {
      axios.post('https://www.ueslms.com/api/v2/hlllogin/user/create', this.userData)
          .then(response => {
            this.userLmsData = response.data.user
            // user_id ekleniyor
            // this.userData.user_idx = response.data.user.user_id;
            // campus_id, ueslms id'si olarak degistiriliyor
            // this.userData.campus_idx = response.data.user.campus_id;
            this.goSensationEnglish()
          })
          .catch(response => {
            console.log('ERR', response)
          })
    },
  }
}
</script>
